.main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    background: transparent;
    .fixed-header & {
        background: $px-white;
        box-shadow: 0 .125rem .25rem rgba($px-dark,.075);
    }
    .navbar {
        padding: 15px 0;
    }
    .navbar-nav {
        > li {
            padding: 0 10px;
            @media(max-width: 991px){
                + li {
                    border-top: 1px solid rgba($px-dark, .1);
                }
            }
            > .nav-link {
                padding: 0 5px;
                line-height: 35px;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 14px;
                color: $px-dark;
                letter-spacing: 3px;
                position: relative;
                @media(max-width: 991px){
                    line-height: 50px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: auto;
                    right: 0;
                    width: 0;
                    height: 2px;
                    background: $px-theme;
                    transition: ease all 0.35s;
                    @media(max-width: 991px){
                        display: none;
                    }
                }
                &:hover,
                &.active {
                    color: $px-theme;
                    &:after {
                        left: 0;
                        right: auto;
                        width: 100%;
                    }
                }
            }
        }
    }
}


@include down-md {
    .main-header {
        .navbar-toggler {
            display: flex;
            flex-direction: column;
            border: none;
            padding: 4px 9px;
            outline: none;
            box-shadow: none;
            span {
                width: 25px;
                height: 2px;
                background: $px-dark;
                margin: 4px 0;
            }
        }
        .navbar-collapse {
            border-bottom: 2px solid $px-dark;
            border-top: 2px solid $px-dark;
            position: absolute;
            top: 100%;
            left: 0px;
            background: $px-white;
            right: 0px;
        }
    }
}