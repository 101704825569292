@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$px-theme:			#FF651C !default;
$px-theme2:			#FEC448 !default;

$px-bg-1:			#9BD3D0 !default;
$px-bg-2:			#FEC447 !default;
$px-bg-3:			#FECCB5 !default;
$px-bg-4:			#92B5FF !default;
$px-bg-5:			#C4D39B !default;
$px-bg-6:			#D2CEFF !default;

$px-dark:			#373737 !default;

$px-white:			#fff !default;
$px-black:			#000 !default;

$px-gray:			rgba($px-theme2, .10) !default;


$px-font-size:		15px !default;
$px-line-height:	1.7 !default;
$px-font:			'Jost', sans-serif !default;


$px-shadow:			0 .375rem 1.5rem 0 rgba(140,152,164,.125) !default;
$px-shadow-sm:		0 12px 15px rgba(140,152,164,.1) !default;


// Breakpoints
$px-media-xsm:		567px !default;
$px-media-sm:		767px !default;
$px-media-md:		991px !default;
$px-media-lg:		1200px !default;