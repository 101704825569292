.footer {
    background-color: $px-dark;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid rgba($px-white, .1);
    color: rgba($px-white, 0.75);
    .nav {
        a {
            font-size: 16px;
            color: rgba($px-white, 0.75);
            &:hover {
                color: $px-theme;
            }
            + a {
                margin-left: 18px;
            }
        }
    }
}