.bg-gray {
	background-color: $px-gray;
}

.bg-dark {
	background-color: $px-dark !important;
}

.bg-1 {
	background-color: $px-bg-1;
}

.bg-2 {
	background-color: $px-bg-2;
}

.bg-3 {
	background-color: $px-bg-3;
}

.bg-4 {
	background-color: $px-bg-4;
}

.bg-5 {
	background-color: $px-bg-5;
}

.bg-6 {
	background-color: $px-bg-6;
}